import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout.jsx"
import Hero from "../components/Hero.jsx"
import Seo from "../components/Seo"
import PlatformPhone from "../assets/images/platform.png"
import NegotiationIcon from "../assets/images/icons/negotiation-plat.png"
import InvestmentIcon from "../assets/images/icons/investment-plat.png"
import AppIcon from "../assets/images/icons/app-plat.png"
import GrahpsIcon from "../assets/images/icons/graphs-plat.png"
import OperationsIcon from "../assets/images/icons/operations-plat.png"
import ProgressIcon from "../assets/images/icons/progress-plat.png"
import CashIcon from "../assets/images/icons/cash-plat.png"
import HistoryIcon from "../assets/images/icons/history-plat.png"
import IconScroll from "../assets/images/icons/scroll-top-icon.svg"

import "../styles/pages/Platform.scss"

export default function Platform() {
  const intl = useIntl()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "platform_title" })} />
      <Hero title={intl.formatMessage({ id: "platform_title" })} text="" />
      <section className="platform-intro">
        <div className="platform-intro__container">
          <div className="platform-intro__box platform-intro__box--text">
            <p className="platform-intro__text">
              {intl.formatMessage({ id: "platform_text" })}
            </p>
          </div>
          <div className="platform-intro__box">
            <img src={PlatformPhone} alt="About" className="about__image" />
          </div>
        </div>
      </section>
      <section className="platform-points">
        <div className="platform-points__container">
          <article className="platform-points__box">
            <img
              src={NegotiationIcon}
              alt="Negotiation"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_negotiation_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_negotiation_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img
              src={InvestmentIcon}
              alt="Investment"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_investment_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_investment_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img src={AppIcon} alt="App" className="platform-points__icon" />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_app_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_app_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img
              src={GrahpsIcon}
              alt="Graphs"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_graphics_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_graphics_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img
              src={OperationsIcon}
              alt="Operations"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_operations_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_operations_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img
              src={ProgressIcon}
              alt="Progress"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_progress_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_progress_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img
              src={CashIcon}
              alt="Balance"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_balance_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_balance_text" })}
            </p>
          </article>
          <article className="platform-points__box">
            <img
              src={HistoryIcon}
              alt="history"
              className="platform-points__icon"
            />
            <h5 className="platform-points__name">
              {intl.formatMessage({ id: "platform_history_title" })}
            </h5>
            <p className="platform-points__text">
              {intl.formatMessage({ id: "platform_history_text" })}
            </p>
          </article>
        </div>
      </section>
      <section className="platform-banner">
        <div className="platform-banner__container">
          <h3 className="platform-banner__title">
            {intl.formatMessage({ id: "platform_banner_title" })}
          </h3>
        </div>
      </section>
      <section className="platform-types">
        <div className="platform-types__container">
          <article className="platform-types__box">
            <h5 className="platform-types__name">
              {intl.formatMessage({ id: "platform_professional_title_one" })}
            </h5>
            <ul className="platform-types__list">
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_professional_text_one" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_professional_text_two" })}
              </li>
              <li className="platform-types__item">
                •{" "}
                {intl.formatMessage({ id: "platform_professional_text_three" })}
              </li>
              <li className="platform-types__item">
                •{" "}
                {intl.formatMessage({ id: "platform_professional_text_four" })}
              </li>
              <li className="platform-types__item">
                •{" "}
                {intl.formatMessage({ id: "platform_professional_text_five" })}
              </li>
            </ul>
            <a
              href="https://clientzone.Adrswap.com/register"
              target="_blank"
              rel="noopener noreferrer"
              className="platform-types__button"
            >
              {intl.formatMessage({ id: "menu_item6" })}
            </a>
          </article>
          <article className="platform-types__box">
            <h5 className="platform-types__name">
              {intl.formatMessage({ id: "platform_investment_title_one" })}
            </h5>
            <ul className="platform-types__list">
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_investment_text_one" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_investment_text_two" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_investment_text_three" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_investment_text_four" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_investment_text_five" })}
              </li>
            </ul>
            <a
              href="https://clientzone.Adrswap.com/register"
              target="_blank"
              rel="noopener noreferrer"
              className="platform-types__button"
            >
              {intl.formatMessage({ id: "menu_item6" })}
            </a>
          </article>
          <article className="platform-types__box">
            <h5 className="platform-types__name">
              {intl.formatMessage({ id: "platform_accounts_title_three" })}
            </h5>
            <ul className="platform-types__list">
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_accounts_text_one" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_accounts_text_two" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_accounts_text_three" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_accounts_text_four" })}
              </li>
              <li className="platform-types__item">
                • {intl.formatMessage({ id: "platform_accounts_text_five" })}
              </li>
            </ul>
            <a
              href="https://clientzone.Adrswap.com/register"
              target="_blank"
              rel="noopener noreferrer"
              className="platform-types__button"
            >
              {intl.formatMessage({ id: "menu_item6" })}
            </a>
          </article>
        </div>
      </section>
      <button id="scroll-btn" className="up__button" title="Scroll to top">
        <img src={IconScroll} alt="Up" className="up__image" />
      </button>
    </Layout>
  )
}
